import {acceptHMRUpdate, defineStore} from 'pinia';

export const useScheduleStore = defineStore({
    id: 'schedule',
    state: () => ({
        schedulePublicationState: '',
        bellPublicationState: '',
        previousScheduleList: [],
        currentScheduleList: [],
    }),

    getters: {
        scheduleState: state => state.schedulePublicationState !== ''
            ? state.schedulePublicationState : 'Не опубликовано',
        bellState: state => state.bellPublicationState !== ''
            ? state.bellPublicationState : 'Не опубликовано',
        currentSchedule: state => state.currentScheduleList,
        previousSchedule: state => state.previousScheduleList
    },

    actions: {
        setScheduleStorage(scheduleObject) {
            this.$patch({
                previousScheduleList: [...scheduleObject],
                currentScheduleList: scheduleObject,
            })
        },

        addItemToSchedule(day, item) {
            let collection = this.currentScheduleList[day - 1].schedule;
            collection.push(item);

            let schedule = this.currentScheduleList.map(item => {
                if (day === item.day_of_week) {
                    return {
                        day_of_week: day,
                        schedule: collection
                    }
                }
                return item
            })

            this.$patch({
                currentScheduleList: schedule
            })
        },

        removeItemFromSchedule(day, item) {

        }
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useScheduleStore, import.meta.hot))
}
