<script>
import TipMessage from "./tip.vue";
export default {
    name: "global-alerts",
    components: {TipMessage},
    data() {
        return{
            env: import.meta.env
        }
    }
}

</script>

<template>
    <tip-message
        v-if="env.VITE_DEMO_MODE !== 'false'"
        type="warning"
        title="Внимание! Система работает в режиме демо!"
    >
        Данные размещенные в формах и меню генерируются автоматически и не сохраняются для дальнейшей работы.
        Для полноценной работы отключите этот режим в настройках платформы
    </tip-message>
</template>

<style scoped>

</style>
