<script>
import {useUserStore} from "~store/user.js";
import TipMessage from "../../../components/helpers/tip.vue";
import {authApiRequests} from "../../../api/auth.js";
import {useDefaultStore} from "~store/default.js";

const environment = import.meta.env;
export default {
    name: "Login",
    components: {TipMessage},
    data() {
        return {
            username: localStorage.getItem('username') ?? (environment.DEV ? 'k.tolmachev' : ''),
            password: environment.DEV ? '123' : '',
            rememberMe: false,
            errors: {
                login: '',
                password: '',
            },
            message: '',
            profile: useUserStore().profile,
            anotherUser: false,
        }
    },
    beforeCreate() {
       if (useUserStore().isAuthenticate()) {
           this.$router.push({path: '/'});
       } else {
           authApiRequests.preAuth()
       }
    },
    methods: {
        useDefaultStore,
        loginAction() {
            this.message = '';
            this.errors = {
                login: '',
                password: '',
            };

           authApiRequests.login(this.username, this.password).then(result => {
                    useUserStore().login(this.username, result);
                    let params = new URLSearchParams(document.location.search);
                    let path = params.get("backUrl")
                    if (path !== null) {
                        this.$router.push({path: path});
                    } else {
                        this.$router.push({name: 'dashboard'})
                    }
            }).catch(reason => {
                if (reason.status === 429) {
                    this.$toast.error('Вы пытались войти слишком много раз. Попробуйте еще раз через несколько минут.');
                } else if(reason.status !== 500 && reason.status !== 401) {
                    console.log(reason);
                 this.errors = reason.data.errors;
                 this.message = reason.data.message;
                }
           });
        }
    }
}
</script>

<template>

    <!-- Page content -->
    <div class="page-content">

        <!-- Main content -->
        <div class="content-wrapper">

            <!-- Inner content -->
            <div class="content-inner">

                <!-- Content area -->
                <div class="content d-flex justify-content-center align-items-center">

                    <!-- Login form -->
                    <form class="login-form" action="/login">
                        <div style="background-color: rgba(255,255,255,0.9)" class="card mb-0">
                            <div class="card-body">
<!--                                <div class="text-center mb-3">-->
<!--                                    <h5 class="mb-0">Авторизация в системе</h5>-->
<!--                                    <span class="d-block text-muted">Введите предоставленные вам данные в поля ниже</span>-->
<!--                                </div>-->

                                <div class="mb-3" v-if="profile === '' || anotherUser">
                                    <label class="form-label">Логин</label>
                                    <div class="form-control-feedback form-control-feedback-start">
                                        <input type="text"
                                               required
                                               class="form-control"
                                               v-model="username"
                                               placeholder="Введите имя пользователя">
                                        <div class="form-control-feedback-icon">
                                            <i class="ph-user-circle text-muted"></i>
                                        </div>
                                    </div>
                                </div>


                                <div class="text-center" v-if="profile.avatar !== undefined && !anotherUser">
                                    <div class="card-img-actions d-inline-block mb-3">
                                        <img class="rounded-circle" :src="profile.avatar" width="160" height="160"
                                             alt="">
                                        <div class="card-img-actions-overlay card-img rounded-circle">
                                            <a href="#" class="btn btn-outline-white btn-icon rounded-pill">
                                                <i class="ph-upload-simple"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mb-3" v-if="profile !== '' && !anotherUser">
                                    <h6 class="mb-0">С возвращением, {{ profile.first_name }}!</h6>
                                    <span class="text-muted">Введите пароль для входа</span>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label">Пароль</label>
                                    <div class="form-control-feedback form-control-feedback-start">
                                        <input
                                            required
                                            type="password"
                                            v-model="password"
                                            class="form-control"
                                            placeholder="Введите пароль">
                                        <div class="form-control-feedback-icon">
                                            <i class="ph-lock text-muted"></i>
                                        </div>
                                    </div>
                                </div>

                                <tip-message
                                    v-if="message !== ''"
                                    :dismiss-button="false"
                                    type="warning"
                                >{{ message }}</tip-message>

                                <div class="mb-3">
                                    <button @click.prevent="loginAction" type="submit" class="btn btn-primary w-100">Войти</button>
                                </div>

                                <div class="text-center">
                                    <a href="/password-recovery">Забыли пароль?</a>
                                    <a href="#" class="mx-4" v-if="profile !== '' && !anotherUser"
                                       @click="anotherUser = true">Не {{ profile.first_name }}?</a>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- /login form -->

                </div>
                <!-- /content area -->

            </div>
            <!-- /inner content -->

            <div class="btn-to-top"><button class="btn btn-secondary btn-icon rounded-pill" type="button"><i class="ph-arrow-up"></i></button></div></div>
        <!-- /main content -->

    </div>
    <!-- /page content -->
</template>

<style scoped>
.page-content {
    min-height: 100vh;
    background-image: url("https://images.unsplash.com/photo-1541829070764-84a7d30dd3f3?q=80&w=3869&auto=format&fit=crop");
    background-size: cover;
    @media (max-width: 400px) {
        background-size: unset;
    }
}
</style>
