<script>
import LayoutNavbar from "./layout/layout.navbar.vue";
import LayoutHeader from "./layout/layout.header.vue";
import LayoutSidebar from "./layout/layout.sidebar.vue";
import LayoutContent from "./layout/layout.content.vue";
import LayoutFooter from "./layout/layout.footer.vue";
import WidgetNotification from "./layout/header/widget/notification.vue";
import LayoutConfig from "./layout/layout.config.vue";
import TipMessage from "./helpers/tip.vue";
import {scheduleEditorMenu} from "../routes/schedule-editor.js";
import {useScheduleStore} from "../stores/schedule.js";
import {useDefaultStore} from "~store/default.js";

export default {
    name: "template-schedule",
    methods: {useDefaultStore, useScheduleStore},
    components: {
        TipMessage, LayoutConfig, WidgetNotification, LayoutFooter, LayoutContent, LayoutSidebar, LayoutHeader,
        LayoutNavbar
    },
    props: {
        offices: {
            type: Object
        },
    },
    data() {
        return {
            menu: scheduleEditorMenu
        }
    },
}
</script>

<template>
    <!-- Main navbar -->
    <layout-navbar></layout-navbar>
    <!-- /Main navbar -->

    <!-- Page header -->
    <layout-header></layout-header>
    <!-- /Page header -->

    <!-- Page content -->
    <div class="page-content pt-0">

    <!-- Main sidebar -->
        <layout-sidebar :menu="menu">
            <template v-slot:schedule-sheet>
                <span class="d-block fw-normal text-body opacity-50" style="color: darkred !important;">
                    {{ useScheduleStore().scheduleState }}</span>
            </template>
            <template v-slot:schedule-bells>
                <span class="d-block fw-normal text-body opacity-50">
                    {{ useScheduleStore().bellState }}</span>
            </template>
        </layout-sidebar>
        <!-- /Main sidebar -->

        <!-- Main content -->
        <layout-content>
                <slot>
                    <tip-message
                        class="m-5"
                        title="Не задано содержимое для текущей страницы"
                        :dismiss-button="false"
                    >
                        Возможно страница к которой вы обращаетесь создана, но не заполнена содержимым.
                        Если вы не знаете почему появилось данное сообщение обратитесь к интегратору,
                        установившему данный экземпляр системы
                    </tip-message>
                </slot>
        </layout-content>
        <!-- /Main content -->
    </div>
    <!-- /Page content -->

    <!-- Footer -->
    <layout-footer></layout-footer>
    <!-- /Footer -->

    <!-- Notifications -->
    <widget-notification></widget-notification>
    <!-- /Notifications -->

    <!-- Visual config -->
    <layout-config></layout-config>
    <!-- /Visual config -->

    <div class="modal-backdrop fade show" v-if="useDefaultStore().modalBackdrop"></div>
</template>

<style scoped>

</style>
