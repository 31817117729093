import TemplateDefault from "../components/template.default.vue";

export const userProfileRoutes = [{
    path: '/profile',
    meta: {
        categoryTitle: "Профиль",
        needAuth: true,
    },
    component: TemplateDefault,
    children: [
        {
            path: '',
            name: 'profile-edit',
            meta: {
                title: "Редактирование профиля",
            },
            component: () => import('../pages/schedule/editor/Bells.vue')
        }, {
            path: 'settings',
            name: 'profile-settings',
            meta: {
                title: "Настройки аккаунта",
            },
            component: () => import('../pages/schedule/editor/Bells.vue')
        },
    ]
}, {
    path: '/schedule',
    component: TemplateDefault,
    meta: {
        categoryTitle: ''
    },
    children: [
        {
            path: '',
            name: 'schedule-user',
            meta: {
                title: "Расписание занятий",
            },
            component: () => import('../pages/schedule/dashboard/ScheduleUser.vue')
        }
    ]
},
];

export const userProfileMenu = [{}];
