<script>
export default {
    name: "widget-notification"
}
</script>

<template>

</template>

<style scoped>

</style>
