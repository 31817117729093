<script>
import {useScheduleStore} from "~store/schedule.js";
import SidebarItem from "=/navigation/sidebar.item.vue";

export default {
    name: "layout-sidebar",
    components: {SidebarItem},
    methods: {useScheduleStore},
    data() {
        return {
            extended: true,
        }
    },
    props: {
        menu: {
            type: Array,
            required: true
        }
    }
}
</script>

<template>
    <div :class="'sidebar sidebar-main sidebar-expand-lg align-self-start ' + (extended ? '': 'sidebar-main-resized') ">

        <!-- Sidebar content -->
        <div class="sidebar-content">

            <!-- Sidebar header -->
            <div class="sidebar-section">
                <div class="sidebar-section-body d-flex justify-content-center">
                    <h5 class="sidebar-resize-hide flex-grow-1 my-auto">Выберите действие</h5>
                    <div>
                        <button
                            type="button"
                            class="btn btn-light btn-icon btn-sm rounded-pill border-transparent sidebar-control sidebar-main-resize d-none d-lg-inline-flex"
                            @click="extended = !extended"
                        >
                            <i class="ph-arrows-left-right"></i>
                        </button>

                        <button type="button" class="btn btn-light btn-icon btn-sm rounded-pill border-transparent sidebar-mobile-main-toggle d-lg-none">
                            <i class="ph-x"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- /sidebar header -->


            <!-- Main navigation -->
            <div class="sidebar-section">
                <ul class="nav nav-sidebar" data-nav-type="accordion">
                    <template v-for="menuCategory in menu">
                        <li class="nav-item-header pt-0">
                            <div class="text-uppercase fs-sm lh-sm opacity-50 sidebar-resize-hide">{{ menuCategory.title }}</div>
                            <i class="ph-dots-three sidebar-resize-show"></i>
                        </li>
                        <template v-for="menuItem in menuCategory.items">
                            <sidebar-item :menu-item="menuItem" :extended.sync="extended">
                                <template v-if="menuItem.subtitleSlot !== undefined">
                                    <slot :name="menuItem.subtitleSlot"></slot>
                                </template>
                            </sidebar-item>
                        </template>
                    </template>
                    <!-- /layout -->

                </ul>
            </div>
            <!-- /main navigation -->

        </div>
        <!-- /sidebar content -->

    </div>
</template>

<style scoped>

</style>
