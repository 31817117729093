<script>
export default {
    name: 'sidebar-item',
    props: {
        menuItem: {
            type: Object,
            required: true,
        },
        extended: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            item: this.menuItem,
            expand: false,
        }
    },
    computed: {
        divider() {
           return this.item.divider !== undefined
        },
        isCurrentElementActiveRoute() {
            if (this.extended === false) {
                return false;
            }
            if (this.menuItem.nested === undefined) {
                return false;
            }

            const nestedRoutes = this.menuItem.nested.map(item => item.route);
            return nestedRoutes.includes(this.$route.name)

        }
    }
}
</script>

<template>
    <li class="nav-item-divider" v-if="divider"></li>
    <li :class="'nav-item'
        + (item.nested !== undefined ? ' nav-item-submenu': '')
        + (item.nested !== undefined && (expand || isCurrentElementActiveRoute) ? ' nav-item-expanded nav-item-open': '')
        + (!extended && (expand || isCurrentElementActiveRoute) ? ' nav-group-sub-visible' : '')">
        <router-link :to="{name: item.route}" class="nav-link" v-if="item.nested === undefined">
            <i :class="item.icon" v-if="item.icon !== undefined"></i>
            <span>
                {{ item.title }}
                <slot></slot>
            </span>
        </router-link>
        <a href="#" @click.prevent = "expand = !expand" class="nav-link" v-else>
            <i :class="item.icon" v-if="item.icon !== undefined"></i>
            <span>{{ item.title }}</span>
        </a>
        <ul :class="'nav-group-sub collapse ' + ((expand || isCurrentElementActiveRoute) ? 'show' : '')" :data-submenu-title="item.title">
        <sidebar-item  v-for="childItem in item.nested" :menu-item="childItem" :extended="extended"></sidebar-item>
    </ul>
    </li>
</template>

<style scoped>
.ph-report {
    max-lines: 1;
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0iIzAwMDAwMCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0yMTAuNzgsMzkuMjVsLTEzMC4yNS0yM0ExNiwxNiwwLDAsMCw2MiwyOS4yM2wtMjkuNzUsMTY5YTE2LDE2LDAsMCwwLDEzLDE4LjUzbDEzMC4yNSwyM2gwYTE2LDE2LDAsMCwwLDE4LjU0LTEzbDI5Ljc1LTE2OUExNiwxNiwwLDAsMCwyMTAuNzgsMzkuMjVaTTE3OC4yNiwyMjRoMEw0OCwyMDEsNzcuNzUsMzIsMjA4LDU1Wk04OS4zNCw1OC40MmE4LDgsMCwwLDEsOS4yNy02LjQ4bDgzLDE0LjY1YTgsOCwwLDAsMS0xLjM5LDE1Ljg4LDguMzYsOC4zNiwwLDAsMS0xLjQtLjEybC04My0xNC42NkE4LDgsMCwwLDEsODkuMzQsNTguNDJaTTgzLjgsODkuOTRhOCw4LDAsMCwxLDkuMjctNi40OWw4MywxNC42NkE4LDgsMCwwLDEsMTc0LjY3LDExNGE3LjU1LDcuNTUsMCwwLDEtMS40MS0uMTNsLTgzLTE0LjY1QTgsOCwwLDAsMSw4My44LDg5Ljk0Wm0tNS41NSwzMS41MUE4LDgsMCwwLDEsODcuNTIsMTE1TDEyOSwxMjIuMjlhOCw4LDAsMCwxLTEuMzgsMTUuODgsOC4yNyw4LjI3LDAsMCwxLTEuNC0uMTJsLTQxLjUtNy4zM0E4LDgsMCwwLDEsNzguMjUsMTIxLjQ1WiI+PC9wYXRoPjwvc3ZnPg==");
}
</style>
