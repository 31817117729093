<script>
import OfficeWidget from "./header/widget/office.vue";

export default {
    name: "layout-header",
    components: {OfficeWidget},
    computed: {
        hideTitle() {
            return this.$route.meta.categoryTitle === undefined && !this.forceShowTitle
                ? 'd-none'
                : ''
        },
        category() {
            return this.$route.meta.categoryTitle ?? 'Не указана категория'
        },
        title() {
            return this.$route.meta.title ?? 'Заголовок не задан'
        }
    },
    props: {
        forceShowTitle: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <div class="page-header">
        <div class="page-header-content d-lg-flex">
            <div :class="'d-flex ' + hideTitle ">
                <h4 class="page-title mb-0">
                    <slot>{{ category }} - <span class="fw-normal">{{ title }}</span></slot>
                </h4>

                <a href="#page_header" class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto" data-bs-toggle="collapse">
                    <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
                </a>
            </div>

            <div :class="(hideTitle === '' ? 'collapse' : '') + ' d-lg-block my-lg-auto ms-lg-auto'" id="page_header">
                <office-widget></office-widget>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
