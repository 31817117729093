export const authApiRequests = {login, logout, preAuth}

// Set XSRF token for query
 async function preAuth() {
    return axios.get('/sanctum/csrf-cookie');
 }

/**
 *
 * Attempt login user
 * @param username
 * @param password
 */
 async function login(username, password) {
     if (!document.cookie.includes('XSRF-TOKEN')) {
         await preAuth();
     }
     return await axios({
        method: 'POST',
        url: '/login',
        data: {
            login: username,
            password: password
        },
    })
        .then(response => {
            return response.data;
        })
        .catch(function (response)  {
            //
            // if (typeof result === "object" && "errors" in result){
            //     this.errors = result.errors;
            //     this.message = result.message;
            // } else if (result.message === 'Too Many Attempts.') {
           // } else {
            return Promise.reject(response.response);
        })
}

// Log out current user
async  function logout() {
     return await axios.post('/logout').then(() => {
         return true;
     }).catch(reason => {
         console.log(reason)
         return Promise.reject(reason);
     });
}



