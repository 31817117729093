import Login from "../pages/service/login/Login.vue";

export const authRoutes = [
    {
        path: '/login',
        name: 'loginPage',
        component: Login
    },
];

