export const profileApiRequest = {getOffices, setOffice}

async function getOffices() {
    await axios.get('/api/my/offices')
        .then(response => {
            return response.data
        })
        .catch(error => {
            return Promise.reject(error.response.data)
        });
}

async function setOffice(id) {
    await axios.post('/api/my/offices/set', {office_id: id})
        .then(response => {
            return response.data
        })
        .catch(error => {
            console.log(error)
            return Promise.reject(error.response.data)
        });
}

//
// async function getProfile() {
//     await axios({
//         method: 'GET',
//         url: '/api/user',
//     })
//         .then(response => {
//             return response.data
//         })
//         .catch(function (response) {
//
//         });
// }
