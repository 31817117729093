import {acceptHMRUpdate, defineStore} from 'pinia';
import {useLocalStorage} from "@vueuse/core";
import CryptoJS from 'crypto-js';
import {profileApiRequest} from "../api/profile.js";
import {useToast} from "vue-toast-notification";

const key = String(import.meta.env.VITE_APP_KEY).replace('base64:', '');
let usr = localStorage.getItem('username') ?? '_0_'

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        profileData: useLocalStorage('profile', ''),
        perms: useLocalStorage(usr.hexEncode(), ''),
        officesList: useLocalStorage('offices', ''),
        notifications: [],
        reAuthState: false,
    }),

    getters: {
        profile: (state) => decrypt(state.profileData) !== ''
            ? JSON.parse(decrypt(state.profileData))
            : '',
        permissions: state => decrypt(state.perms) !== ''
            ? JSON.parse(decrypt(state.perms))
            : {},
        offices: (state) => decrypt(state.officesList) !== ''
            ? JSON.parse(decrypt(state.officesList))
            : null,
    },

    actions: {
        logout() {
            this.$patch({
                profileData: '',
                perms: '',
                officesList: '',
                reAuthState: true
            })
            localStorage.clear();
        },

        /**
         * Attempt to log in a user
         */
         login(username, data) {
            usr = username;
            localStorage.setItem('username', username)
            this.$patch({
                notifications: data.unread_notifications ?? [],
                profileData: crypt(JSON.stringify(data.profile)),
                perms: crypt(JSON.stringify(data.permissions)),
                officesList: crypt(JSON.stringify(data.offices)),
                reAuthState: false,
            })
        },

        isAuthenticate() {
          return document.cookie.includes('XSRF-TOKEN') && this.profile !== '' && !this.needReAuth()
        },

        needReAuth() {
          return this.reAuthState;
        },
        setAuthRepeat(value) {
          this.$patch({
              reAuthState: value,
          })
        },

        changeOffice(id){
            profileApiRequest.setOffice(id)
                .then(() => {
                    let offices = this.offices
                    offices = offices.map((office) => {
                        office.active = (office.id === id)
                        return office
                    });

                    this.$patch({
                        officesList: crypt(JSON.stringify(offices))
                    });
                })
                .catch(reason => {
                    let toast = useToast()
                    toast.error('Ошибка переключения на другой филиал')
                })

        },
        currentOffice() {
            return this.offices.map((item) => {
                if (item.active) {
                    return item;
                }
            })[0]
        }
    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}

function decrypt(data) {
    try {
        return data !== ''
            ? CryptoJS.AES.decrypt(data, usr + key).toString(CryptoJS.enc.Utf8)
            : ''
    } catch (e) {
        localStorage.clear()
        window.location.reload()
    }

}

function crypt(data) {
   return CryptoJS.AES.encrypt(data, usr + key).toString()
}


function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

String.prototype.hexEncode = function(){
    let hex, i;

    let result = "";
    for (i=0; i<this.length; i++) {
        hex = this.charCodeAt(i).toString(16);
        result += ("000"+hex).slice(-4);
    }

    return result
}
