import {acceptHMRUpdate, defineStore} from 'pinia';
import {useUserStore} from "~store/user.js";

export const useDefaultStore = defineStore({
    id: 'default',
    state: () => ({
        backdropModalTrigger: false,
    }),

    getters: {
        modalBackdrop: state => state.backdropModalTrigger,
    },

    actions: {
        setModalBackdrop(value) {
            this.$patch({
                backdropModalTrigger: value
            })
        }
    },
})


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
