<script>
export default {
    name: "layout-footer"
}
</script>

<template>
    <div class="navbar navbar-sm navbar-footer border-top">
        <div class="container-fluid">
            <span>© 2024 Образовательные решения: Колледж</span>

            <ul class="nav">
                <li class="nav-item">
                    <a href="https://t.me/DarkTke" class="navbar-nav-link navbar-nav-link-icon rounded" target="_blank">
                        <div class="d-flex align-items-center mx-md-1">
                            <i class="ph-lifebuoy"></i>
                            <span class="d-none d-md-inline-block ms-2">Support</span>
                        </div>
                    </a>
                </li>
                <!--                <li class="nav-item ms-md-1">-->
                <!--                    <a href="" class="navbar-nav-link navbar-nav-link-icon rounded" target="_blank">-->
                <!--                        <div class="d-flex align-items-center mx-md-1">-->
                <!--                            <i class="ph-file-text"></i>-->
                <!--                            <span class="d-none d-md-inline-block ms-2">Docs</span>-->
                <!--                        </div>-->
                <!--                    </a>-->
                <!--                </li>-->
                <!--                <li class="nav-item ms-md-1">-->
                <!--                    <a href="" class="navbar-nav-link navbar-nav-link-icon text-primary bg-primary bg-opacity-10 fw-semibold rounded" target="_blank">-->
                <!--                        <div class="d-flex align-items-center mx-md-1">-->
                <!--                            <i class="ph-shopping-cart"></i>-->
                <!--                            <span class="d-none d-md-inline-block ms-2">Purchase</span>-->
                <!--                        </div>-->
                <!--                    </a>-->
                <!--                </li>-->
            </ul>
        </div>
    </div>
</template>

<style scoped>

</style>
