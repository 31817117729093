<script>
// noinspection JSFileReferences
import {useUserStore} from "~store/user.js";

export default {
    name: "office-widget",
    computed: {
        isRightMenageOffice() {
            // return this.permissions.includes('manage-offices')
        },
        isRightChangeBetweenOffice() {
            // return this.permissions.includes('switch-offices')
        },
        currentOffice() {
            if (this.offices !== ''){
                return this.offices.filter((office) => office.active)[0] ?? {}
            }
            return {
                logo: '',
                name: '',
            }
        },
        offices() {
            return useUserStore().offices
        }
    },
    methods: {
      setActiveOffice: function (id){
          useUserStore().changeOffice(id);
      }
    },
    data() {
        return {
            permissions: useUserStore().permissions,

        }
    },
}
</script>

<template>
    <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
        <div class="dropdown w-100 w-sm-auto">
            <a href="#" class="d-flex align-items-center text-body lh-1 dropdown-toggle py-sm-2" data-bs-toggle="dropdown" data-bs-display="static">
                <img :src="currentOffice.logo" class="w-32px h-32px me-2" :alt="'Логотип филиала ' + currentOffice.name">
                <div class="me-auto me-lg-1">
                    <div class="fs-sm text-muted mb-1">Филиал</div>
                    <div class="fw-semibold">{{ currentOffice.name }}</div>
                </div>
            </a>

            <div class="dropdown-menu dropdown-menu-lg-end w-100 w-lg-auto wmin-300 wmin-sm-350 pt-0">
                <div class="d-flex align-items-center p-3">
                    <h6 class="fw-semibold mb-0">Филиалы</h6>
                    <a href="#" class="ms-auto" v-if="isRightMenageOffice">
                        Управление филиалами
                        <i class="ph-arrow-circle-right ms-1"></i>
                    </a>
                </div>
                <template v-for="office in offices">
                    <a href="#"
                       :class="'dropdown-item py-2 ' + office.active"
                       @click="setActiveOffice(office.id)"
                    >
                        <img
                            class="w-32px h-32px me-2"
                            :src="office.logo"
                            :alt="'Логотип филиала ' + office.name"
                        >
                        <div>
                            <div class="fw-semibold">{{ office.name }}</div>
                            <div class="fs-sm text-muted">{{ office.location }}</div>
                        </div>
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
