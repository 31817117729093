<script>
export default {
    name: "tip-message",
    props: {
        type: {
            type: String,
            default: "info"
        },
        title: {
            type: String,
        },
        dismissButton: {
            type: Boolean,
            default: true,
        }
    }
}
</script>

<template>
    <div :class="'alert '
    + ('alert-' + type + ' ')
    + (dismissButton ? 'alert-dismissible' : '')
    ">
        <div class="alert-heading fw-semibold" v-if="title !== ''">
            {{ title }}
        </div>
        <slot></slot>
        <button type="button" class="btn-close" data-bs-dismiss="alert" v-if="dismissButton"></button>
    </div>
</template>

<style scoped>

</style>
