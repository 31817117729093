<script>


import GlobalAlerts from "../helpers/global.alerts.vue";

export default {
    name: "layout-content",
    components: {GlobalAlerts}
}
</script>

<template>
    <!-- Content area -->
    <div class="content">

        <!-- Info alert -->
       <global-alerts/>
        <!-- /info alert -->

        <router-view v-slot="{ Component }">
                <component :is="Component" />
        </router-view>
    </div>
    <!-- /content area -->
</template>

<style scoped>

</style>
