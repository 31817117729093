<script>
export default {
    name: "layout-config"
}
</script>

<template>

</template>

<style scoped>

</style>
