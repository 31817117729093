<script>
import TipMessage from "../../../helpers/tip.vue";

export default {
    name: "message-widget-navbar",
    components: {TipMessage}
}
</script>

<template>
    <li class="nav-item nav-item-dropdown-lg dropdown ms-lg-2">
        <a href="#" class="navbar-nav-link navbar-nav-link-icon rounded-pill"
           @click="this.$toast.info('В ранних версиях функционал системы личных сообщений отключен')"
        >
<!--           data-bs-toggle="dropdown" -->
<!--           data-bs-auto-close="outside">-->

            <i class="ph-chats"></i>
<!--            <span class="badge bg-yellow text-black position-absolute top-0 end-0 translate-middle-top zindex-1 rounded-pill mt-1 me-1">0</span>-->
        </a>

        <div class="dropdown-menu wmin-lg-400 p-0">
            <div class="d-flex align-items-center p-3">
                <h6 class="mb-0">Личные сообщения</h6>
                <div class="ms-auto">
                    <a href="#" class="text-body">
                        <i class="ph-plus-circle"></i>
                    </a>
                    <a href="#search_messages" class="collapsed text-body ms-2" data-bs-toggle="collapse">
                        <i class="ph-magnifying-glass"></i>
                    </a>
                </div>
            </div>

            <div class="collapse" id="search_messages">
                <div class="px-3 mb-2">
                    <div class="form-control-feedback form-control-feedback-start">
                        <input type="text" class="form-control" placeholder="Search messages">
                        <div class="form-control-feedback-icon">
                            <i class="ph-magnifying-glass"></i>
                        </div>
                    </div>
                </div>
            </div>

<!--            <div class="dropdown-menu-scrollable pb-2">-->
<!--                <a href="#" class="dropdown-item align-items-start text-wrap py-2">-->
<!--                    <div class="status-indicator-container me-3">-->
<!--                        <img src="#" class="w-40px h-40px rounded-pill" alt="">-->
<!--                        <span class="status-indicator bg-warning"></span>-->
<!--                    </div>-->

<!--                    <div class="flex-1">-->
<!--                        <span class="fw-semibold">James Alexander</span>-->
<!--                        <span class="text-muted float-end fs-sm">04:58</span>-->
<!--                        <div class="text-muted">who knows, maybe that would be the best thing for me...</div>-->
<!--                    </div>-->
<!--                </a>-->

<!--                <a href="#" class="dropdown-item align-items-start text-wrap py-2">-->
<!--                    <div class="status-indicator-container me-3">-->
<!--                        <img src="#" class="w-40px h-40px rounded-pill" alt="">-->
<!--                        <span class="status-indicator bg-success"></span>-->
<!--                    </div>-->

<!--                    <div class="flex-1">-->
<!--                        <span class="fw-semibold">Margo Baker</span>-->
<!--                        <span class="text-muted float-end fs-sm">12:16</span>-->
<!--                        <div class="text-muted">That was something he was unable to do because...</div>-->
<!--                    </div>-->
<!--                </a>-->

<!--                <a href="#" class="dropdown-item align-items-start text-wrap py-2">-->
<!--                    <div class="status-indicator-container me-3">-->
<!--                        <img src="#" class="w-40px h-40px rounded-pill" alt="">-->
<!--                        <span class="status-indicator bg-success"></span>-->
<!--                    </div>-->
<!--                    <div class="flex-1">-->
<!--                        <span class="fw-semibold">Jeremy Victorino</span>-->
<!--                        <span class="text-muted float-end fs-sm">22:48</span>-->
<!--                        <div class="text-muted">But that would be extremely strained and suspicious...</div>-->
<!--                    </div>-->
<!--                </a>-->

<!--                <a href="#" class="dropdown-item align-items-start text-wrap py-2">-->
<!--                    <div class="status-indicator-container me-3">-->
<!--                        <img src="#" class="w-40px h-40px rounded-pill" alt="">-->
<!--                        <span class="status-indicator bg-grey"></span>-->
<!--                    </div>-->
<!--                    <div class="flex-1">-->
<!--                        <span class="fw-semibold">Beatrix Diaz</span>-->
<!--                        <span class="text-muted float-end fs-sm">Tue</span>-->
<!--                        <div class="text-muted">What a strenuous career it is that I've chosen...</div>-->
<!--                    </div>-->
<!--                </a>-->

<!--                <a href="#" class="dropdown-item align-items-start text-wrap py-2">-->
<!--                    <div class="status-indicator-container me-3">-->
<!--                        <img src="#" class="w-40px h-40px rounded-pill" alt="">-->
<!--                        <span class="status-indicator bg-danger"></span>-->
<!--                    </div>-->
<!--                    <div class="flex-1">-->
<!--                        <span class="fw-semibold">Richard Vango</span>-->
<!--                        <span class="text-muted float-end fs-sm">Mon</span>-->
<!--                        <div class="text-muted">Other travelling salesmen live a life of luxury...</div>-->
<!--                    </div>-->
<!--                </a>-->
<!--            </div>--><tip-message type="warning" :dismiss-button="false" class="p-3">Функционал сообщений еще в разработке</tip-message>
            <div class="d-flex border-top py-2 px-3">
                <a href="#" class="text-body">
                    <i class="ph-checks me-1"></i>
                    Отметить прочитанными
                </a>
                <a href="#" class="text-body ms-auto">
                    Показать все
                    <i class="ph-arrow-circle-right ms-1"></i>
                </a>
            </div>
        </div>
    </li>
</template>

<style scoped>

</style>
