<script>
import LayoutNavbar from "=/layout.navbar.vue";

import LayoutContent from "=/layout.content.vue";

import TipMessage from "./helpers/tip.vue";
import LayoutHeader from "=/layout.header.vue";
import LayoutConfig from "=/layout.config.vue";
import WidgetNotification from "=/header/widget/notification.vue";
import LayoutFooter from "=/layout.footer.vue";
import {useDefaultStore} from "~store/default.js";

export default {
    name: "template-default",
    methods: {useDefaultStore},
    components: {
        LayoutFooter, WidgetNotification, LayoutConfig,
        LayoutHeader,
        TipMessage,  LayoutContent, LayoutNavbar
    },
    props: {
        offices: {
            type: Object
        },
    },
    data() {
        return {

        }
    },
}
</script>

<template>
    <!-- Main navbar -->
    <layout-navbar></layout-navbar>
    <layout-header class="mt-2" :force-show-title="true"></layout-header>
    <!-- /Main navbar -->

    <!-- Page content -->
    <div class="page-content pt-0">
        <!-- Main content -->
        <layout-content>
                <slot>
                    <tip-message
                        class="m-5"
                        title="Не задано содержимое для текущей страницы"
                        :dismiss-button="false"
                    >
                        Возможно страница к которой вы обращаетесь создана, но не заполнена содержимым.
                        Если вы не знаете почему появилось данное сообщение обратитесь к интегратору,
                        установившему данный экземпляр системы
                    </tip-message>
                </slot>
        </layout-content>
        <!-- /Main content -->
    </div>
    <!-- /Page content -->

    <!-- Footer -->
    <layout-footer></layout-footer>
    <!-- /Footer -->

    <!-- Notifications -->
    <widget-notification></widget-notification>
    <!-- /Notifications -->

    <!-- Visual config -->
    <layout-config></layout-config>
    <!-- /Visual config -->

    <div class="modal-backdrop fade show" v-if="useDefaultStore().modalBackdrop"></div>
</template>

<style scoped>

</style>
