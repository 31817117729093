import Home from "../pages/Home.vue";
import TemplateDefault from "../components/template.default.vue";

export const statRoutes = [
    {
        path: '/',
        name: 'dashboard',
        component: Home,
    },
    // 404 Route
    {
        path: '/:pathMatch(.*)*',
        component: TemplateDefault,
    },
];
