<script>
import {useUserStore} from "~store/user.js";
import {authApiRequests} from "../../../../api/auth.js";

export default {
    name: "profile",
    methods: {
        logoutAction() {
            authApiRequests.logout().then(() => {
                useUserStore().logout();
                this.$router.push({name: 'loginPage'})
            })
        }
    },
    data() {
        return{
            profile: useUserStore().profile,
            permissions: useUserStore().permissions,
        }
    }
}
</script>

<template>
    <li class="nav-item nav-item-dropdown-lg dropdown ms-lg-2">
        <a href="#" class="navbar-nav-link align-items-center rounded-pill p-1" data-bs-toggle="dropdown">
            <div class="status-indicator-container">
                <img :src="profile.avatar" class="w-32px h-32px rounded-pill" alt="Аватар пользователя">
                <span class="status-indicator bg-success"></span>
            </div>
            <span class="d-none d-lg-inline-block mx-lg-2">{{ profile.username}}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-end">
            <router-link
                class="dropdown-item"
                :to="{name: 'profile-edit'}"
            >
                <i class="ph-user-circle me-2"></i>
                Профиль
            </router-link>

            <div class="dropdown-divider"
            ></div>

            <router-link
                class="dropdown-item"
                :to="{name: 'admin-dashboard'}">
                <i class="ph-wrench me-2"></i>
                Администрирование платформы
            </router-link>

            <div class="dropdown-divider"></div>
            <router-link
                class="dropdown-item"
                :to="{name: 'profile-settings'}">
                    <i class="ph-gear me-2"></i>
                    Настройки аккаунта
            </router-link>

            <a href="#" @click="logoutAction" class="dropdown-item">
                <i class="ph-sign-out me-2"></i>
                Выход
            </a>
        </div>
    </li>
</template>

<style scoped>

</style>
