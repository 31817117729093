<template>
    <div>
        <layout-navbar/>
        <layout-header class="mt-2" :force-show-title="true">
             <span class="fw-normal">{{ greetings }}</span> {{ profile.first_name + ' ' + profile.second_name }}
        </layout-header>

        <!-- Page content -->
        <div class="page-content mt-0">
            <layout-sidebar :menu="menu"></layout-sidebar>
            <!-- Main content -->
            <global-alerts></global-alerts>
            <!-- /Main content -->
            <div class="content">
                <tip-message
                    :dismiss-button="false"
                    title="Системное уведомление"
                    type="primary">Это специальная демо страница для быстрого перехода к нужным модулям. Существует только на первичном этапе разработки и будет заменена на дашборард под каждую роль пользователя. Также вы можете в любой момент попасть в выбор модулей нажав <i class="ph-squares-four"></i></tip-message>

            </div>
                   </div>
    </div>

    <layout-footer></layout-footer>
</template>

<script>
import GlobalAlerts from "../components/helpers/global.alerts.vue";
import LayoutContent from "=/layout.content.vue";
import TemplateDefault from "../components/template.default.vue";
import LayoutNavbar from "=/layout.navbar.vue";
import TipMessage from "../components/helpers/tip.vue";
import LayoutFooter from "=/layout.footer.vue";
import LayoutHeader from "=/layout.header.vue";
import {useUserStore} from "../stores/user.js";
import LayoutPage from "=/layout.page.vue";
import LayoutSidebar from "=/layout.sidebar.vue";


export default {
    data() {
        return {
            profile: useUserStore().profile,
            menu: [    {
                title: 'Список модулей',
                items: [
                    {
                        route: 'schedule-sheet',
                        icon: 'ph-layout',
                        title: 'Электронное расписание',
                    }, {
                        route: 'schedule-user',
                        icon: 'ph-layout',
                        title: 'Электронное расписание для пользователя',
                    }, {
                        route: 'journal-main',
                        icon: 'ph-report',
                        title: 'Электронные журналы',
                    },

                ]
            }]
        }
    },
    computed: {
      greetings() {
          const d = new Date();
          let t = d.getHours();
          if ( t >= 5 && t < 11) {
              return 'Доброе утро,'
          } else if (t >= 11 && t < 17) {
              return 'Добрый день,'
          } else if (t >= 17 && t < 23) {
              return 'Добрый вечер,'
          } else {
              return 'Доброй ночи,'
          }
      }
    },
    beforeCreate() {
        this.$router.push({name: 'schedule-sheet'});
    },
    components: {
        LayoutSidebar,
        LayoutPage,
        GlobalAlerts, LayoutHeader, LayoutFooter,  TipMessage, LayoutContent, LayoutNavbar, TemplateDefault},
}

</script>
