// Class app dependencies loader

// Vue
import {createApp} from 'vue';
import App from './App.vue';
// Pinia storage
import {createPinia} from "pinia";
// Vue router
import router from './router.js';
// CryptoJS
import VueCryptojs from 'vue-cryptojs'
// Toast plugin
import ToastPlugin, {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
// Axios default
import axios from 'axios';
import {useUserStore} from "~store/user.js";
// Add sentry monitoring
import * as Sentry from "@sentry/vue";
//Add calendar
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App);

const stateStorage = createPinia()
const notify = useToast()
app.use(stateStorage)

app.use(router);

app.use(VueCryptojs)
app.use(ToastPlugin)

app.component('VueDatePicker', VueDatePicker);


axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        if (!useUserStore().needReAuth()) {
            useUserStore().setAuthRepeat(true);
            notify.error('Время сессии истекло, авторизуйтесь снова', {
                position: 'top',
                duration: 5000,
            })
            router.push({name: "loginPage"})
        }
    }

    if (error.response.status === 403) {
        notify.error('Вы не можете выполнить данный запрос с текущими правами доступа')
    }


    if (error.response.status === 500) {
        notify.error('Произошла системная ошибка. Не волнуйтесь мы уже работаем над этим' +
            (import.meta.env.VITE_ENV !== 'production' ? ' - ' + error.response.data.message : ''),{
            position: 'top',
            duration: 0,
        })
    }
    return Promise.reject(error);
});

window.axios = axios;

if (import.meta.env.VITE_APP_ENV === 'production') {
    Sentry.init({
        app,
        dsn: "https://3941ad2c40148a79f0d978941165aecd@o4506785957281792.ingest.sentry.io/4506785959837696",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

export default app;
