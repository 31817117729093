import TemplateDefault from "../components/template.default.vue";

export const adminRoutes = [{
    path: '/admin',
    meta: {
        categoryTitle: 'Администрирование платформы',
        needAuth: true,
        permission: 'manage-platform',
    },
    component: TemplateDefault,
    children: [
        {
            path: '',
            name: 'admin-dashboard',
            meta: {
                title: "Тест",
            },
            component: () => import('../pages/schedule/editor/Bells.vue')
        },
    ]
}];
