import TemplateJournal from "../components/template.journal.vue";

export const journalModuleRoutes = [{
    path: '/journal',
    meta: {
        categoryTitle: "Журнал оценок",
        needAuth: true,
    },
    component: TemplateJournal,
    children: [
        {
            path: '',
            name: 'journal-main',
            meta: {
                title: "Главная страница",
            },
            component: () => import('../pages/journal/Default.vue')
        }, {
            path: '',
            name: 'journal-help',
            meta: {
                title: "Справочное руководство",
            },
            component: () => import('../pages/journal/Default.vue')
        },
    ]},
];

export const journalMenu = [
    {
        title: 'Журнал преподавателя',
        items: [
            {
                route: 'journal-main',
                icon: 'ph-report',
                title: 'Журнал преподавателя',
                subtitleSlot: 'teaching-load'
            },
            // {route: 'dashboard', icon: 'ph-printer', title: 'Печать расписания'},
        ]
    },
    {
        title: 'Дополнительно',
        items: [
            {route: 'journal-help', title: 'Документация по работе', icon: 'ph-question', divider: true},
        ]
    },
];
