import TemplateSchedule from "../components/template.schedule.vue";

export const scheduleEditorModuleRoutes = [{
        path: '/schedule/editor',
        meta: {
            categoryTitle: "Расписание",
            needAuth: true,
        },
        component: TemplateSchedule,
        children: [
            {
                path: '',
                name: 'schedule-sheet',
                meta: {
                    title: "Редактор сетки расписания",
                },
                component: () => import('../pages/schedule/editor/Sheet.vue')
            },  {
                path: 'bells',
                name: 'schedule-bells',
                meta: {
                    title: "Расписание звонков",
                },
                component: () => import('../pages/schedule/editor/Bells.vue')
            }, {
                path: 't-load',
                name: 'teaching-load',
                meta: {
                    title: "Учебная нагрузка преподавателей",
                },
                component: () => import('../pages/schedule/editor/TeachersLoad.vue')
            }, {
                path: 'conditions',
                name: 'conditions',
                meta: {
                    title: "Специальные условия",
                },
                component: () => import('../pages/schedule/editor/Conditions.vue')
            }, {
                path: 'periods',
                name: 'periods',
                meta: {
                    title: "Периоды обучения",
                },
                component: () => import('../pages/schedule/editor/Periods.vue')
            }, {
                path: 'curriculum',
                meta: {
                    categoryTitle: "Учебные планы"
                },
                children : [
                    {
                        path: 'import',
                        name: 'curriculum-import',
                        meta: {
                            title: "Импорт учебного плана",
                        },
                        component: () => import('../pages/schedule/editor/curriculums/Import.vue')
                    },
                    {
                        path: 'list',
                        name: 'curriculum-list',
                        meta: {
                            title: "Список планов",
                        },
                        component: () => import('../pages/schedule/editor/curriculums/CurriculumList.vue')

                    },
                    {
                        path: 'list/modules',
                        name: 'curriculum-discipline',
                        meta: {
                            title: "Список дисциплин и модулей",
                        },
                        component: () => import('../pages/schedule/editor/curriculums/DisciplineList.vue')

                    },
                    {
                        path: 'lecture-types',
                        name: 'curriculum-lecture-types',
                        meta: {
                            title: "Тип занятий",
                        },
                        component: () => import('../pages/schedule/editor/curriculums/LectureTypes.vue')

                    },

                ]
            }, {
                path: 'rooms',
                meta: {
                    categoryTitle: "Учебные помещения"
                },
                children : [
                    {
                        path: 'import',
                        name: 'rooms-import',
                        meta: {
                            title: "Импорт помещений",
                        },
                        component: () => import('../pages/schedule/editor/rooms/RoomsImport.vue')
                    },
                    {
                        path: 'list',
                        name: 'rooms-list',
                        meta: {
                            title: "Список аудиторий",
                        },
                        component: () => import('../pages/schedule/editor/rooms/RoomsList.vue')

                    },
                    {
                        path: 'corps',
                        name: 'corps-list',
                        meta: {
                            title: "Список учебных корпусов",
                        },
                        component: () => import('../pages/schedule/editor/rooms/CorpsList.vue')

                    },
                    {
                        path: 'room-types',
                        name: 'room-types',
                        meta: {
                            title: "Типы аудиторий",
                        },
                        component: () => import('../pages/schedule/editor/rooms/RoomTypes.vue')

                    },

                ]
            }, {
                path: 'teachers',
                meta: {
                    categoryTitle: "Преподавательский состав"
                },
                children : [
                    {
                        path: 'import',
                        name: 'teachers-import',
                        meta: {
                            title: "Импорт списка преподавателей",
                        },
                        component: () => import('../pages/schedule/editor/teachers/TeachersImport.vue')
                    },
                    {
                        path: 'list',
                        name: 'teachers-list',
                        meta: {
                            title: "Список преподавателей",
                        },
                        component: () => import('../pages/schedule/editor/teachers/TeachersList.vue')

                    },

                ]
            }, {
                path: 'groups',
                meta: {
                    categoryTitle: "Учебные группы"
                },
                children : [
                    {
                        path: 'import',
                        name: 'groups-import',
                        meta: {
                            title: "Импорт списка групп",
                        },
                        component: () => import('../pages/schedule/editor/groups/GroupsImport.vue')
                    },
                    {
                        path: 'list',
                        name: 'groups-list',
                        meta: {
                            title: "Список учебных групп",
                        },
                        component: () => import('../pages/schedule/editor/groups/GroupsList.vue')

                    }, {
                        path: 'list',
                        name: 'groups-subgroup-list',
                        meta: {
                            title: "Список подгрупп",
                        },
                        component: () => import('../pages/schedule/editor/groups/SubgroupList.vue')

                    },

                ]
            }, {
                path: 'help',
                name: 'schedule-help',
                meta: {
                    title: "Документация по работе с системой",
                },
                component: () => import('../pages/schedule/editor/Help.vue')
            },
        ],
},


];

export const scheduleEditorMenu = [
    {
        title: 'Редактор расписания',
        items: [
            {
                route: 'schedule-sheet',
                icon: 'ph-layout',
                title: 'Сетка расписания',
                subtitleSlot: 'schedule-sheet'
            },  {
                route: 'schedule-bells',
                icon: 'ph-bell-ringing',
                title: 'Расписание звонков',
                subtitleSlot: 'schedule-bells'
            }, {
                route: 'teaching-load',
                icon: 'ph-report',
                title: 'Учебная нагрузка преподавателей',
                subtitleSlot: 'teaching-load'
            },
            {route: 'conditions', icon: 'ph-warning', title: 'Особые условия'},
        ]
    },
    {
        title: 'Справочники',
        items: [
            {route: 'periods', icon: 'ph-calendar-check', title: 'Учебные периоды'},
            {
                icon: 'ph-users-four',
                title: 'Преподавательский состав',
                nested: [
                    {route: 'teachers-list', title: 'Список преподавателей'},
                    {route: 'teachers-import', title: 'Импорт преподавателей', divider: true},
                ]
            },
            {
                icon: 'ph-house-simple',
                title: 'Учебные помещения',
                nested: [
                    {route: 'corps-list', title: 'Список учебных корпусов'},
                    {route: 'rooms-list', title: 'Список аудиторий'},
                    {route: 'room-types', title: 'Типы аудиторий'},
                    {route: 'rooms-import', title: 'Импорт аудиторий', divider: true},
                ]
            },
            {
                icon: 'ph-user-list',
                title: 'Учебные группы и подгруппы',
                nested: [
                    {route: 'groups-list', title: 'Список учебных групп'},
                    {route: 'groups-subgroup-list', title: 'Список учебных подгрупп'},
                    {route: 'groups-import', title: 'Импорт учебных групп', divider: true},
                ]
            },
            {
                icon: 'ph-suitcase-simple',
                title: 'Учебные планы',
                nested: [
                    {route: 'curriculum-list', title: 'Список учебных планов'},
                    {route: 'curriculum-discipline', title: 'Список дисциплин и модулей'},
                    {route: 'curriculum-lecture-types', title: 'Типы занятий'},
                    {route: 'curriculum-import', title: 'Импорт учебных планов', divider: true},
                ]
            },
            {route: 'schedule-help', title: 'Документация по работе', icon: 'ph-question', divider: true},
        ]
    },
];
