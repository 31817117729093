<template>
    <router-view v-slot="{ Component }">
            <component :is="Component" />
    </router-view>
</template>

<script>

</script>

<style scoped>
.component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to {
    opacity: 0;
}
</style>
