<script>
export default {
    name: "layout-page",
    props: {
        title: {
            type: String,
            default: "Не указан заголовок"
        },
    }
}
</script>

<template>
    <div class="card">
        <slot name="title">
            <div class="card-header">
                <h5 class="mb-0">{{ title }}</h5>
            </div>
        </slot>

        <div class="card-body">
            <slot name="default"></slot>
        </div>
        <div class="card-footer bg-light d-flex justify-content-between align-items-center">
            <slot name="footer">
                <a href="#" class="btn btn-light btn-icon"><i class="ph-question"></i></a>
            </slot>
        </div>
    </div>
</template>

<style scoped>

</style>
