import {createRouter, createWebHistory} from 'vue-router';
import {useUserStore} from "~store/user.js";
import {scheduleEditorModuleRoutes} from "./routes/schedule-editor.js"
import {journalModuleRoutes} from "./routes/journal.js";
import {authRoutes} from "./routes/auth.js";
import {statRoutes} from "./routes/default.js";
import {userProfileRoutes} from "./routes/profile.js";
import {adminRoutes} from "./routes/admin.js";
import {useToast} from "vue-toast-notification";

const routes = [
    ...scheduleEditorModuleRoutes,
    ...journalModuleRoutes,
    ...authRoutes,
    ...adminRoutes,
    ...userProfileRoutes,
    ...statRoutes
];

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
});

const publicPage = [
    '/login',
    '/password-reset',
    '/privacy-policy',
    '/agreement'
];

router.beforeEach(async (to, from) =>  {
    {
        if(!publicPage.includes(to.path) && (!useUserStore().isAuthenticate() || useUserStore().needReAuth())) {
            return '/login?backUrl=' + to.path
        }

        if (to.meta["permission"] !== undefined && !useUserStore().permissions.includes(to.meta["permission"])) {
            const toast = useToast()
            toast.error('У вас недостаточно полномочий для входа в данный раздел', { duration: 5000})
            return false
        }

        if (to.meta["categoryTitle"] !== undefined
            || to.meta["title"] !== undefined) {
            document.title = (to.meta["title"] !== undefined
                    ? to.meta["title"] + ' - '
                    : (to.meta["categoryTitle"] !== undefined ? to.meta["categoryTitle"] + ': ' : ''))
                + "Образовательные решения: Колледж"

        }
    }
})


export default router;
