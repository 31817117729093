<script>
import {useUserStore} from "~store/user.js";
import Profile from "=/header/widget/profile.vue";
import MessageWidgetNavbar from "=/header/widget/messages.vue";
import SearchWidgetNavbar from "=/header/widget/search.vue";
import WidgetNotification from "=/header/widget/notification.vue";

export default {
    name: "layout-navbar",
    methods: {useUserStore},
    components: {WidgetNotification, SearchWidgetNavbar, MessageWidgetNavbar, Profile},
}
</script>

<template>
    <div class="navbar navbar-dark navbar-expand-lg navbar-static">
        <div class="container-fluid">
            <div class="d-flex d-lg-none me-2">
                <button type="button" class="navbar-toggler sidebar-mobile-main-toggle rounded-pill">
                    <i class="ph-list"></i>
                </button>
            </div>

            <div class="navbar-brand flex-1 flex-lg-0">
                    <router-link :to="{name: 'dashboard'}" class="d-inline-flex align-items-center">
                        <img src="/assets/images/logo_icon.svg" alt="">
                        <img src="/assets/images/logo_text_light.svg" class="d-none d-sm-inline-block h-32px ms-3" alt="">
                    </router-link>
            </div>

            <ul class="nav flex-row">
                <li class="nav-item d-lg-none">
                    <a href="#navbar_search" class="navbar-nav-link navbar-nav-link-icon rounded-pill" data-bs-toggle="collapse">
                        <i class="ph-magnifying-glass"></i>
                    </a>
                </li>

                <li class="nav-item nav-item-dropdown-lg dropdown">
                    <a href="#" class="navbar-nav-link navbar-nav-link-icon rounded-pill" data-bs-toggle="dropdown">
                        <i class="ph-squares-four"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-scrollable-sm wmin-lg-600 p-0">
                        <div class="d-flex align-items-center border-bottom p-3">
                            <h6 class="mb-0">Установленные модули</h6>
                            <a href="#"
                               @click="this.$toast.error('У вас недостаточно полномочий для входа в данный раздел', { duration: 5000})"
                               class="ms-auto">
                                Показать все
                                <i class="ph-arrow-circle-right ms-1"></i>
                            </a>
                        </div>

                        <div class="row row-cols-1 row-cols-sm-2 g-0">
                            <div class="col">
                                <router-link :to="{ name: 'schedule-sheet'}">
                                    <button type="button" class="dropdown-item text-wrap h-100 align-items-start border-end-sm border-bottom p-3">
                                        <div>
                                            <img src="/assets/images/schedule.svg" class="h-40px mb-2" alt="">
                                            <div class="fw-semibold my-1">Электронное расписание</div>
                                            <div class="text-muted">Редакция 1.0 preAlfa</div>
                                        </div>
                                    </button>
                                </router-link>

                            </div>

                            <div class="col">
                                <router-link :to="{ name: 'journal-main'}">
                                <button type="button" class="dropdown-item text-wrap h-100 align-items-start border-bottom p-3">
                                    <div>
                                        <img src="/assets/images/clipboard-with-a-.svg" class="h-40px mb-2" alt="">
                                        <div class="fw-semibold my-1">Журнал оценок</div>
                                        <div class="text-muted">Редакция 1.0 preAlfa</div>
                                    </div>
                                </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </li>

                <message-widget-navbar></message-widget-navbar>
            </ul>

            <search-widget-navbar></search-widget-navbar>

            <ul class="nav flex-row justify-content-end order-1 order-lg-2">
                <li class="nav-item ms-lg-2">
                    <a href="#" class="navbar-nav-link navbar-nav-link-icon rounded-pill"
                       @click="this.$toast.info('В ранних версиях функционал системы уведомлений отключен')">
<!--                       data-bs-toggle="offcanvas"-->
<!--                       data-bs-target="#notifications">-->
                        <i class="ph-bell"></i>
                        <span class="badge bg-yellow text-black position-absolute top-0
                        end-0 translate-middle-top zindex-1 rounded-pill mt-1 me-1" v-if="useUserStore().notifications.length > 0">{{ useUserStore().notifications.length }}</span>
                    </a>
                </li>
               <profile></profile>
            </ul>
        </div>
    </div>
</template>

<style scoped>

</style>
